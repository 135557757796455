export const LOG_IN_ERROR = 'Failed to log in. Please try again after 1 minute!';
export const LOG_OUT_ERROR = 'Failed to log out. Please try again after 1 minute!';
export const RESET_PASSWORD_ERROR = 'Failed to reset password. Please try again after 1 minute!';
export const SEND_RESET_PASSWORD_ERROR = "Failed to send resetting password's email. Please try again after 1 minute!";
export const REPORT_MENTOR_FAILED = 'Failed to report a mentor. Please try again after 1 minute!';
export const REVIEW_MENTOR_FAILED = 'Failed to write a review for a mentor. Please try again after 1 minute!';
export const UPDATE_MENTOR_AVAILABILITY_FAILED = 'Failed to update your availability. Please try again after 1 minute!';
export const UPDATE_PROFILE_FAILED = 'Failed to update your profile. Please try again after 1 minute!';
export const REQUEST_MENTOR_FAILED = 'Failed to apply for a mentor.Please try again after 1 minute!';
export const DUPLICATE_CUSTOMIZED_URL = 'The public url you entered is not available!';
export const UPDATE_CUSTOMIZED_URL_FAILED = 'Failed to update your customized url.Please try again after 1 minute!';
export const PROPOSE_BOOKING_FAILED = 'Failed to propose the booking.Please try again after 1 minute!';
export const ACCEPT_BOOKING_FAILED = 'Failed to accept the booking.Please try again after 1 minute!';
export const REJECT_BOOKING_FAILED = 'Failed to reject the booking.Please try again after 1 minute!';
export const CANCEL_BOOKING_FAILED = 'Failed to cancel the booking.Please try again after 1 minute!';
export const WITHDRAW_BOOKING_FAILED = 'Failed to withdraw the booking.Please try again after 1 minute!';
export const CANCEL_BOOKING_LATE = 'Unfortunately, you cannot cancel this session 12 hours in advance';
