import { HTTPRequest, RequestRespond } from '../HTTPRequest';
import { getAnalytics, Analytics, logEvent } from 'firebase/analytics';

export enum MONITOR_EVENT {
  LOGIN = 'login',
  SIGNUP = 'signup',
  VISIT_MENTOR = 'visit_mentor',
  MEETING_SCHEDULED = 'meeting_scheduled',
  MENTOR_REQUEST = 'mentor_request',
  USER_UPDATE = 'user_update',
  SCHEDULE_A_CALL = 'schedule_a_call',
  VISIT_MY_PROFILE = 'visit_my_profile',
  MANAGE_ACCOUNT = 'manage_account',
  HOMEPAGE = 'homepage',
  MENTOR_PAGE = 'mentor_page',
  REVIEW_PAGE = 'review_page',
  BOOKING_PAGE = 'booking_page',
  BECOME_A_MENTOR = 'become_a_mentor',
  FINISH_BOOKING_MEETING = 'finish_booking_meeting',
}

export enum ERROR_EVENT {
  LOGIN_ERROR = 'LOGIN_ERROR',
}

interface LogInterface {
  /* monitorUserActivity will monitor all the activities when user perform an action (e.g create a new account)
   * @param {eventName} Name of the event
   */
  monitorUserActivity(eventName: MONITOR_EVENT, eventParams?: { [key: string]: string | undefined });
}

class LService extends HTTPRequest implements LogInterface {
  // All the endpoints or API call that relates to user service
  protected firebaseAnalytic: Analytics;

  public constructor() {
    super();
    this.firebaseAnalytic = getAnalytics(this.firebaseInstance);
  }

  /* monitorUserActivity will monitor all the activities when user perform an action (e.g create a new account)
   * @param {eventName} Name of the event
   */
  public monitorUserActivity(
    eventName: MONITOR_EVENT,
    eventParams?: { [key: string]: string | undefined }
  ): RequestRespond<undefined> {
    try {
      logEvent(this.firebaseAnalytic, eventName.toString(), eventParams);
      return { success: true };
    } catch (error: unknown) {
      return { success: false };
    }
  }

  public monitorSystemError(
    eventName: ERROR_EVENT,
    eventParams?: { [key: string]: string }
  ): RequestRespond<undefined> {
    try {
      logEvent(this.firebaseAnalytic, eventName, eventParams);
      return { success: true };
    } catch (error: unknown) {
      return { success: false };
    }
  }
}

export const LoggingService = new LService();
